import type { RouteObject } from 'react-router-dom'
import { createBrowserRouter, Outlet, RouterProvider, useNavigate, useSearchParams } from 'react-router-dom'
import { GlobalStyles } from '@mui/material'

import { AcceptInvitationPage, AdminPage, CoachingPage, DashboardPage, DashboardPageContent, DiscPage, MeetingCopilotPage, MeetingPage, RecordMeetingPage, UploadMeetingPage } from '@zel-labs/coaching'
import { LqPage } from '@zel-labs/lq'
import React, { useEffect, useMemo } from 'react'
import { SentryReportingErrorBoundary } from './SentryReportingErrorBoundary'
import { ProtectedRoute, useAppContext, useUserContext } from '@zel-labs/auth'
import { signInWithCustomToken } from 'firebase/auth'
import { useFirebaseAuth } from '@zel-labs/shared/firebase'
import { Intercom, shutdown, update } from '@intercom/messenger-js-sdk'


function ErrorBoundaryLayout() {
  return <SentryReportingErrorBoundary>
    <Outlet />
  </SentryReportingErrorBoundary>
}


const routes: RouteObject[] = [
  {
    element: <ErrorBoundaryLayout />,
    children: [
      { index: true, element: <DashboardPage /> },
      { path: 'customtoken', element: <CustomTokenPage /> },
      { path: 'signUp', element: <SignUpPage /> },
      { path: 'admin', element: <AdminPage /> },
      { path: 'invitations/:invitationId', element: <AcceptInvitationPage /> },
      { path: 'coaching/:coachingId', element: <CoachingPage /> },
      { path: 'coaching/:coachingId/upload', element: <UploadMeetingPage /> },
      { path: 'coaching/:coachingId/record', element: <RecordMeetingPage /> },
      { path: 'coaching/:coachingId/meetings/:meetingId', element: <MeetingPage /> },
      { path: 'coaching/:coachingId/meetings/:meetingId/copilot', element: <MeetingCopilotPage /> },
      { path: 'coaching/:coachingId/meetings/:meetingId/lq', element: <LqPage /> },
      { path: 'coaching/:coachingId/meetings/:meetingId/disc', element: <DiscPage /> }
    ]
  }
]

function SignUpPage() {
  const navigate = useNavigate()
  const { user } = useUserContext()
  useEffect(
    () => {
      if (user != null) {
        navigate('/')
      }
    }, [navigate, user]
  )
  return <ProtectedRoute authMethod="signUp">
    <DashboardPageContent />
  </ProtectedRoute>
}

function CustomTokenPage() {
  const navigate = useNavigate()
  const { user } = useUserContext()
  const { auth } = useFirebaseAuth()
  const [searchParams] = useSearchParams()
  const appContext = useAppContext()

  useEffect(() => {

      const fetchCredentials = async (customToken: string) => {
        const credential = await signInWithCustomToken(auth, customToken)
        if (credential) {
          appContext.setHeaderless(true)
          navigate('/')
        }
      }

      const customToken = searchParams.get('customtoken')
      console.log('Custom Token:', searchParams.toString())
      if (customToken) {
        fetchCredentials(customToken)
      }

    }, [navigate, user, searchParams, auth, appContext]
  )
  return ''
}

export function ZelLabsRouterProvider() {
  const { user } = useUserContext()
  Intercom({
    app_id: 'm5yz99vq'
  })

  const intercomStyles = {
    '.intercom-lightweight-app-launcher': {
      bottom: '90px !important'
    },
    '.intercom-app > div:nth-of-type(2)': {
      bottom: '90px !important'
    }
  }

  useEffect(() => {
    console.log('user:', user)
    if (user) {
      update({
        user_id: user.uid,
        name: user.displayName ?? user.email ?? 'user',
        email: user.email ?? 'user@',
        created_at: user.metadata.creationTime
          ? Math.floor(new Date(user.metadata.creationTime).getTime() / 1000)
          : -1
      })
    } else {
      shutdown()
    }
  }, [user])

  const router = useMemo(
    () =>
      createBrowserRouter(routes),
    []
  )

  return (
    <>
      <GlobalStyles styles={intercomStyles} />
      <RouterProvider router={router} />
    </>
  )
}


export default routes
