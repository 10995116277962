import {useMemo} from 'react'
import type {DocumentSnapshot} from 'firebase/firestore'
import {doc} from 'firebase/firestore'
import {typeConverter, useFirestoreSnapshot} from '@zel-labs/shared/firebase'
import type {Coaching, Meeting} from '@zel-labs/shared/model'


export function useMeeting(meetingId: string | undefined, coachingSnapshot: DocumentSnapshot<Coaching> | null | undefined ) {
  const meetingReference = useMemo(
    () =>
      meetingId == null || coachingSnapshot == null
        ? null
        : doc(coachingSnapshot.ref, 'meetings', meetingId).withConverter(
          typeConverter<Meeting>()
        ),
    [meetingId, coachingSnapshot]
  )
  const meetingSnapshot = useFirestoreSnapshot(meetingReference)

  const meetingTitle = useMemo(() => {
    if (meetingSnapshot != null) {
      const data = meetingSnapshot.data()
      if (data != null) {
        return data.title ?? data.recordingFileName
      }
    }
  }, [meetingSnapshot])

  return {meetingSnapshot, meetingTitle}
}
