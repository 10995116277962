import {Typography, Unstable_Grid2 as Grid} from '@mui/material'
import {typeConverter, useFirestore, useFirestoreResults,} from '@zel-labs/shared/firebase'
import {useUserContext} from '@zel-labs/auth'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import {collection, query, where} from 'firebase/firestore'
import React, {useMemo} from 'react'
import type {Coaching} from '@zel-labs/shared/model'
import type {CoachingRole} from './CoachingContext'
import {CoachingContextProvider} from './CoachingContext'
import {CoachingCard} from './CoachingCard'


interface CoachingsProps {
  role: CoachingRole;
  title: string;
  coachings: QueryDocumentSnapshot<Coaching>[] | null | undefined
}

export function Coachings({role, title, coachings}: CoachingsProps) {
  if (coachings == null) {
    return null
  }
  return <>
    <Typography variant="h2" mt={4} gutterBottom={true}>
      {title}
    </Typography>
    <Grid container spacing={4} rowSpacing={4}>
      {coachings?.map((snapshot) => <Grid key={snapshot.id} xs={12} md={6} lg={4}>
        <CoachingContextProvider role={role} coaching={snapshot}>
          <CoachingCard/>
        </CoachingContextProvider>
      </Grid>)}
      {role === 'coach' && <Grid key="add" xs={12} md={6} lg={4}></Grid>}
    </Grid>
  </>
}

export function useCoachings(mode: CoachingRole) {
  const {user, roles} = useUserContext()
  const firestore = useFirestore()

  const collectionReference = useMemo(
    () => {
      return collection(firestore, 'coachings').withConverter(typeConverter<Coaching>())
    },
    [firestore]
  )

  const filter = useMemo(() => {
    if (user == null) {
      return undefined
    }
    const userId = user.uid
    if (mode === 'admin') {
      if (roles.includes('admin')) {
        return collectionReference
      } else {
        return null
      }
    } else if (mode === 'coach') {
      return query(collectionReference, where('coach.uid', '==', userId))
    } else {
      return query(collectionReference, where('coachee.uid', '==', userId))
    }
  }, [user, mode, roles, collectionReference])


  return useFirestoreResults(filter, {waitForWrites:true})
}
