import {useCoachingContext} from '../coaching'
import {useMeetingContext} from '../meeting'
import {useCallback, useMemo} from 'react'
import type {DocumentReference, Timestamp} from 'firebase/firestore'
import { serverTimestamp} from 'firebase/firestore'
import {addDoc, collection, query, where} from 'firebase/firestore'
import {typeConverter, useFirestoreResults} from '@zel-labs/shared/firebase'
import type {Meeting} from '@zel-labs/shared/model'


export interface DiscAssessment {
  assessmentType: 'disc'
  meetingRef: DocumentReference<Meeting>
  assessment?: string | null
  suggestions?: string | null
  createdAt: Timestamp
}

export function useDiscAssessments() {
  const {coaching} = useCoachingContext()
  const {meeting, data} = useMeetingContext()

  const assessmentCollection = useMemo(
    () => {
      if (coaching != null) {
        return collection(coaching.ref, 'assessments')
      }
    },
    [coaching]
  )
  const assessmentsQuery = useMemo(
    () => {
      if (assessmentCollection != null && meeting != null) {
        return query(
          assessmentCollection,
          where('assessmentType', '==', 'disc'),
          where('meetingRef', '==', meeting.ref)
        ).withConverter(typeConverter<DiscAssessment>())
      }
    },
    [assessmentCollection, meeting]
  )

  const assessments = useFirestoreResults(assessmentsQuery)

  const requestAssessment = useCallback(
    () => {
      if (assessmentCollection != null && assessments?.length === 0 && meeting != null && data?.transcript !=null ) {
        addDoc(assessmentCollection, {
          assessmentType: 'disc',
          meetingRef: meeting.ref,
          createdAt: serverTimestamp()
        })
      }
    },
    [assessmentCollection, assessments, data, meeting]
  )

  return {assessments, requestAssessment}
}
