import type {UserRole} from './UserContext'
import { useUserContext} from './UserContext'
import { useAuthenticationContext } from '.'
import {AuthDialog, PasswordResetForm, SignInForm, SignUpForm} from './components'
import type { PropsWithChildren} from 'react'
import { useEffect } from 'react'
import { Forbidden } from '@zel-labs/shared/firebase'

export interface ProtectedRouteProps extends PropsWithChildren {
  forRoles?: UserRole[];
  authMethod?: 'signIn' | 'signUp';
}

export function ProtectedRoute({ children, forRoles = [], authMethod = 'signIn',  }: ProtectedRouteProps) {
  const { user, state, roles } = useUserContext()
  const { hideAuthentication, authenticationMode, signIn, signUp } = useAuthenticationContext()

  useEffect(
    () => {
      if (state==='success' && user==null) {
        if (authMethod==='signIn') {
          signIn()
        } else {
          signUp()
        }
      }
    }, [signIn, signUp, user, state, authMethod]
  )

  if (state==='pending') {
    return null
  }

  if (user==null) {
    return <AuthDialog open={authenticationMode!=null} onClose={hideAuthentication} fullWidth={true}>{
      authenticationMode==='signIn'
        ? <SignInForm />
        :authenticationMode==='signUp'
          ? <SignUpForm />
          :<PasswordResetForm />
    }</AuthDialog>
  }

  if (forRoles.length > 0 && !forRoles.some(role => roles.includes(role))) {
    throw new Forbidden()
  }

  return children
}
