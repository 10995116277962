import {Chip, IconButton, Stack, styled, Typography} from '@mui/material'
import {useMeetingContext} from '../meeting'
import {CopilotButton} from './CopliotButton'
import {ActionChip} from './ActionChip'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import {Routing} from '@zel-labs/routing'
import AssessmentIcon from '@mui/icons-material/Assessment'
import DeleteIcon from '@mui/icons-material/Delete'
import React, {useCallback, useEffect, useState} from 'react'
import {updateDoc} from 'firebase/firestore'
import { typeConverter } from '@zel-labs/shared/firebase'
import type {Coaching} from '@zel-labs/shared/model'
import {useCoachingContext} from '../coaching'
import {Trans, useTranslation} from 'react-i18next'
import ReplayIcon from '@mui/icons-material/Replay'
import CancelIcon from '@mui/icons-material/Cancel'
import {getFunctions, httpsCallable} from 'firebase/functions'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import {getDownloadURL, getStorage, ref} from 'firebase/storage'
import {useUserContext} from '@zel-labs/auth'
import { trackEvent, useFirebaseAnalytics, useFirebaseApp } from '@zel-labs/shared/firebase'

export function MeetingActions() {
  const {meeting, data} = useMeetingContext()
  const navigate = useNavigate()
  const {roles} = useUserContext()
  const {role} = useCoachingContext()
  const {t} = useTranslation()
  const { analytics } = useFirebaseAnalytics()

  const deleteMeeting = useCallback(() => {
    if (meeting != null) {
      const confirmed = window.confirm(t('meeting.actions.delete-meeting-confirmation'))
      if (confirmed) {
        updateDoc(meeting.ref, {status: 'delete'})
        trackEvent(analytics, 'meeting_delete_click', {
          meeting: meeting.ref.path,
          coaching: meeting.ref.parent.parent?.path
        })
        const coachingRef = meeting.ref.parent.parent?.withConverter(typeConverter<Coaching>())
        if (coachingRef != null) {
          navigate(Routing.coaching(coachingRef))
        } else {
          navigate(Routing.home())
        }
      }
    }
  }, [meeting, navigate, t, analytics])

  const app = useFirebaseApp()
  const [transcriptUrl, setTranscriptUrl] = useState<string | null>()

  useEffect(
    () => {
      if (roles.includes('admin')) {
        const transcriptPath = data?.transcript
        if (data == null || transcriptPath == null) {
          return
        }
        const storage = getStorage(app)
        const reference = ref(storage, transcriptPath)
        getDownloadURL(reference).then(
          (url) => {
            setTranscriptUrl(url)
          }
        )
      }
    }, [app, data, roles])


  const retryAnalysis = useCallback(
    async () => {
      if (meeting != null && meeting.data()?.retry_analysis !== true) {
        const retry_function = httpsCallable(getFunctions(app, 'europe-west1'), 'retry_analysis')
        trackEvent(analytics, 'meeting_retry_analysis_click', {
          meeting: meeting.ref.path,
          coaching: meeting.ref.parent.parent?.path
        })
        await retry_function(meeting.ref.path)
      }
    }, [analytics, app, meeting])

  const cancelAnalysis = useCallback(
    async () => {
      if (meeting != null) {
        const confirmed = window.confirm(t('meeting.actions.cancel-meeting-confirmation'))
        if (confirmed) {
          updateDoc(meeting.ref, {status: 'analysis_failed'})
          trackEvent(analytics, 'meeting_cancel_click', {
            meeting: meeting.ref.path,
            coaching: meeting.ref.parent.parent?.path
          })
        }
      }
    }, [analytics, meeting, t])


  if (data == null || meeting == null) {
    return null
  }

  if (['transcribed', 'analyzed', null, undefined].includes(data.status)) {
    return <MeetingActionsContainer direction="row">
      <CopilotButton meeting={meeting}/>
      <Stack direction="row" flexGrow={1} justifyContent="flex-end" gap={1}>
        <ActionChip disabled={data.transcript == null} clickable size="small" variant="outlined"
                    component={RouterLink} to={Routing.lq(meeting.ref)}
                    icon={<AssessmentIcon color="secondary" fontSize="small"/>}
                    label={t('meeting.assessments.lq.title')}/>
        <ActionChip disabled={data.transcript == null} clickable size="small" variant="outlined"
                    component={RouterLink} to={Routing.disc(meeting.ref)}
                    icon={<AssessmentIcon color="info" fontSize="small"/>}
                    label={t('meeting.assessments.disc.title')}/>
        {roles.includes('admin') &&
          <Chip disabled={transcriptUrl == null}
                clickable size="small" variant="outlined"
                download={`${data.title ?? 'meeting'}-transcript.txt`}
                icon={<TextFieldsIcon color="info" fontSize="small"/>}
                component="a" href={transcriptUrl ?? ''}
                label={t('meeting.actions.download-transcript')}/>}
      </Stack>
      {['coachee', 'coach'].includes(role) &&
        <IconButton size="small" onClick={deleteMeeting}><DeleteIcon/></IconButton>}
    </MeetingActionsContainer>
  }

  return <MeetingActionsContainer direction="row">
    <Stack direction="row" flexGrow={1} spacing={1} alignItems="center">
      <Typography variant="overline"><Trans i18nKey={`meeting.statuses.${data.status}`}/></Typography>
      <Typography variant="overline">
        {data.progress != null ? `${data.progress}%` : '...'}
      </Typography>
      {['coachee', 'coach', 'admin'].includes(role) && data.status === 'analysis_failed' && data.retry_analysis !== true &&
        <IconButton size="small" color="warning" onClick={retryAnalysis}><ReplayIcon/></IconButton>}
      {['coachee', 'coach', 'admin'].includes(role) && data.status === 'analyzing' &&
        <IconButton size="small" color="warning" onClick={cancelAnalysis}><CancelIcon/></IconButton>}
    </Stack>
    {['coachee', 'coach', 'admin'].includes(role) && data.progress == null && !['analyzing', 'preprocessing'].includes(data.status) &&
      <IconButton size="small" onClick={deleteMeeting}><DeleteIcon/></IconButton>}
  </MeetingActionsContainer>
}

export const MeetingActionsContainer = styled(Stack, {
  name: 'InterviewActions',
  slot: 'Root'
})(({theme}) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
  justifyContent: 'stretch',
  alignItems: 'center'
}))

