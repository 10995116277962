import React, { useEffect, useMemo } from 'react'
import {
  Stack
} from '@mui/material'
import {MeetingActions, SummaryMarkdown} from '../components'
import {useMeetingContext} from './MeetingContext'
import {ContentHeader} from '@zel-labs/shared/mui'
import {useTranslation} from 'react-i18next'
import {NewMeetingSpeedDial} from '../components/NewMeetingSpeedDial'
import {QuoteSearch} from './ReplayMeeting'
import { trackEvent } from '@zel-labs/shared/firebase'
import { useFirebaseAnalytics } from '@zel-labs/shared/firebase'

export function MeetingDetails() {
  const {data, meetingDate, meeting} = useMeetingContext()
  const { analytics } = useFirebaseAnalytics()
  const {t} = useTranslation()

  const meetingPath = useMemo(() => meeting?.ref.path, [meeting])

  useEffect(() => {
    if (meetingPath != null) {
      trackEvent(analytics, 'meeting_analysis_opened', {
        meeting: meetingPath
      })
    }
  }, [analytics, meetingPath])

  return <Stack direction="column" spacing={2} mb={6}>
    <ContentHeader title={data?.title ?? data?.recordingFileName}
                   subtitle={t('formats.short-date', {date: meetingDate})}/>
    <MeetingActions/>
    <SummaryMarkdown>{data?.meetingNotes}</SummaryMarkdown>
    {data?.status === 'analyzed' && <QuoteSearch/>}
    <NewMeetingSpeedDial/>
  </Stack>
}

