import { logEvent } from 'firebase/analytics'
import type { Analytics, AnalyticsCallOptions } from '@firebase/analytics'

type AnalyticsEvent =
  | 'dashboard_opened'
  | 'coaching_create_opened'
  | 'coaching_create_submitted'
  | 'coaching_update_opened'
  | 'coaching_update_submitted'
  | 'coachee_invite_opened'
  | 'coachee_invitation_sent'
  | 'meeting_update_opened'
  | 'meeting_upload_opened'
  | 'meeting_upload_submitted'
  | 'meeting_recorder_opened'
  | 'meeting_recorder_started'
  | 'meeting_recorder_stopped'
  | 'meeting_recorder_failed'
  | 'meeting_analysis_opened'
  | 'meeting_disc_opened'
  | 'meeting_lq_opened'
  | 'meeting_cancel_click'
  | 'meeting_delete_click'
  | 'meeting_retry_analysis_click'
  | 'signup_success'
  | 'signup_opened'
  | 'signup_google_started'
  | 'signup_password_submitted'
  | 'signin_success'
  | 'signin_opened'
  | 'signin_google_started'
  | 'signin_password_submitted'
  | 'copilot_opened'
  | 'copilot_chat_created'
  | 'copilot_chat_submitted'
  | 'invitation_page_opened'
  | 'invitation_accepted'
  | 'quote_search_submitted'

/**
 * Logs an analytics event both to the analytics service and console
 * @param eventName The name of the event to log
 * @param properties Optional properties to include with the event
 */
export function trackEvent(analyticsInstance: Analytics, eventName: AnalyticsEvent, eventParams?: { [key: string]: unknown; }, options?: AnalyticsCallOptions) {
  const isDev = process.env.NODE_ENV === 'development'
  if (isDev) {
    // Log to console
    console.log(`[Analytics] ${eventName}`, eventParams || '')
  } else {
    // Log to analytics service
    logEvent(analyticsInstance, eventName, eventParams, options)
  }
}
