import React, { useMemo } from 'react'

import type {
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import { query } from 'firebase/firestore'
import { collection, orderBy } from 'firebase/firestore'
import { typeConverter, useFirestoreResults } from '@zel-labs/shared/firebase'
import type { Coaching, Meeting } from '@zel-labs/shared/model'

export type CoachingRole = 'coachee' | 'coach' | 'admin';

export interface CoachingContextType {
  coaching: DocumentSnapshot<Coaching> | null | undefined;
  meetings: QueryDocumentSnapshot<Meeting>[] | null | undefined;
  role: CoachingRole;
  coachingTitle: string | undefined;
  coachingSubtitle: string | undefined | null;
}

export const CoachingContext = React.createContext<CoachingContextType | undefined>(undefined)

interface CoachingContextProviderProps extends React.PropsWithChildren {
  coaching: DocumentSnapshot<Coaching> | undefined | null;
  role: CoachingRole;
}

export function CoachingContextProvider({coaching, role, children}: CoachingContextProviderProps) {
  const meetingsCollection = useMemo(() => {
    if (coaching != null) {
      const c = collection(coaching.ref, 'meetings').withConverter(
        typeConverter<Meeting>()
      )
      return query(c, orderBy('uploadedAt', 'desc'))
    }
  }, [coaching])

  const  coachingTitle = useMemo(() => {
    if (coaching != null && role != null) {
      const data = coaching.data()
      if (data != null) {
        switch (role) {
          case 'coachee':
            return data.coach.name
          default:
            return data.coachee.name
        }
      }
    }
  }, [role, coaching])


  const coachingSubtitle = useMemo(() => {
    if (coaching != null && role != null) {
      const data = coaching.data()
      if (data != null) {
        switch (role) {
          case 'admin':
            return data.coach.name
          default:
            return null
        }
      }
    }

  }, [role, coaching])

  const meetings = useFirestoreResults(meetingsCollection)

  const context = {
    coaching: coaching,
    meetings,
    role,coachingTitle, coachingSubtitle
  }

  return (
    <CoachingContext.Provider value={context}>
      {children}
    </CoachingContext.Provider>
  )
}

export function useCoachingContext() {
  const context = React.useContext(CoachingContext)
  if (context == null) {
    throw new Error('Cannot use Coaching Context outside of a provider')
  }
  return context
}
