import type { FirebaseApp } from 'firebase/app'
import { initializeApp } from 'firebase/app'
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useMemo } from 'react'
import type { FirebaseOptions } from '@firebase/app'

export interface FirebaseContextType {
  app: FirebaseApp
}

export const FirebaseAppContext = createContext<FirebaseContextType | undefined>(undefined)

export interface FirebaseConfig {
  storageBucket: string;
  apiKey: string;
  messagingSenderId: string;
  appId: string;
  projectId: string;
  measurementId?: string;
  authDomain: string;
}

export interface FirebaseContextProviderProps extends PropsWithChildren {
  config: FirebaseOptions;
}

export function FirebaseAppContextProvider({ config, children }: FirebaseContextProviderProps) {
  const app = useMemo(() => initializeApp(config), [config])

  return <FirebaseAppContext.Provider value={{ app }}>
    {children}
  </FirebaseAppContext.Provider>
}


export function useFirebaseApp() {
  const firebase = useContext(FirebaseAppContext)
  if (!firebase) {
    throw new Error('useFirebase must be used within a FirebaseProvider')
  }
  return firebase.app
}

