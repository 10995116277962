import type {DocumentReference, DocumentSnapshot, QueryDocumentSnapshot} from 'firebase/firestore'
import {collection} from 'firebase/firestore'
import type {PropsWithChildren, ReactNode} from 'react'
import {createContext, useContext, useMemo} from 'react'
import type {Chat, ChatMessage} from './types'
import type {MessageThread, MessageTree} from './MessageThread'
import {useMessageThreadManager} from './MessageThread'
import {usePromptSubmmitter} from './PromptSubmit'
import {useChatsContext} from './ChatsContext'
import { typeConverter, useFirestoreResults, useFirestoreSnapshot } from '@zel-labs/shared/firebase'

export type ContentComponentType =
  (props: { children: string | null | undefined }) => ReactNode

export type SubmitState = 'idle' | 'posting' | 'pending' | 'listening' | 'failed'

export interface ChatContextType {
  chat: DocumentSnapshot | undefined | null;

  messages: QueryDocumentSnapshot<ChatMessage>[] | undefined | null;
  ContentComponent?: ContentComponentType;

  submitPrompt?: (prompt: string) => void;
  submitState: SubmitState | undefined;
  samplePrompts?: string[];

  thread: MessageThread | undefined;
  selectThread: (thread: MessageTree) => void;
  startThread: (thread: MessageTree) => void;
}


const ChatContext = createContext<ChatContextType | undefined>(undefined)

interface ChatContextProviderProps extends PropsWithChildren {
  mode: 'chat' | 'view';
  ContentComponent?: ContentComponentType;
  samplePrompts?: string[];
}

export function ChatContextProvider(props: ChatContextProviderProps) {
  const {
    mode, ContentComponent, children,
    samplePrompts
  } = props

  const {selectedChat, selectChat, createChat} = useChatsContext()
  const {chat, messages} =
    useChat(selectedChat)
  const {thread, selectThread, startThread} =
    useMessageThreadManager(messages)
  const {submitState, submitPrompt} =
    usePromptSubmmitter(chat, createChat, selectChat, thread, mode)

  return <ChatContext.Provider value={{
    chat, messages, thread,
    submitPrompt, submitState,
    selectThread, startThread,
    ContentComponent, samplePrompts
  }}>{children}</ChatContext.Provider>
}

export function useChatContext() {
  const context = useContext(ChatContext)
  if (context == null) {
    throw new Error('useChatContext must be used within a ChatContextProvider')
  }
  return context
}

function useChat(chatReference: DocumentReference<Chat> | null | undefined) {
  const chat = useFirestoreSnapshot(chatReference, {waitForWrites: true})
  const messagesCollection = useMemo(() => {
    return chat === null
      ? null
      : chat === undefined
        ? undefined
        : collection(chat.ref, 'messages')
          .withConverter(typeConverter<ChatMessage>())
  }, [chat])

  const messages = useFirestoreResults(messagesCollection, {waitForWrites: true})

  return {chat, messagesCollection, messages}
}
