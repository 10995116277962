import type { DocumentReference, DocumentSnapshot } from 'firebase/firestore'
import { getDoc, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useFirebaseAuth } from '../firebase/FirebaseAuthContext'
import type { HttpError} from '../errors'
import { Forbidden, InternalError } from '../errors'
import { Unauthorized } from '../errors'

interface FirestoreResultsOptions {
  watch?: boolean
  waitForWrites?: boolean
}

const defaultOptions = {
  watch: true,
  waitForWrites: false
}
export function useFirestoreSnapshot<T>(
  ref: DocumentReference<T> | undefined | null,
  options?: FirestoreResultsOptions): DocumentSnapshot<T> | undefined | null {
  const [result, setResult] =
    useState<DocumentSnapshot<T> | null>()
  const [error, setError] = useState<HttpError|null>()
  const {user} = useFirebaseAuth()

  useEffect(() => {
    if (ref===undefined) {
      setResult(undefined)
      setError(undefined)
      return
    } else if (ref===null) {
      setResult(null)
      setError(null)
      return
    }
    getDoc(ref)
      .then((snapshot) => {
        setResult(snapshot)
      })
      .catch((error) => {
        if (error.code==='permission-denied') {
          if (user === null) {
            setError(new Unauthorized(error, {query: JSON.stringify(ref)}))
          } else {
            setError(new Forbidden(error, {query: JSON.stringify(ref)}))
          }
        } else {
          setError(new InternalError(error, {ref: JSON.stringify(ref)}))
        }
      })
  }, [ref, user])

  useEffect(() => {
    const watch = options?.watch ??  defaultOptions.watch
    const waitForWrites = options?.waitForWrites ?? defaultOptions.waitForWrites

    if (ref!=null && watch) {

      const unsubscribe = onSnapshot(ref,{ includeMetadataChanges: true }, (snapshot) => {
        if (!waitForWrites || !snapshot.metadata.hasPendingWrites) {
          setResult(snapshot)
        }
      })

      return () => {
        unsubscribe()
      }
    }
  }, [ref, options?.waitForWrites, options?.watch])

  useEffect(
    () => {
      if (error!=null) {
        throw error
      }
    },
    [error]
  )

  return result
}
