import type {
  DocumentReference,
  DocumentSnapshot,
  FirestoreError
} from 'firebase/firestore'
import {
  addDoc,
  collection,
  onSnapshot,
  serverTimestamp, type Unsubscribe, type WithFieldValue
} from 'firebase/firestore'
import type {AgentMessage, Chat, ChatMessage, UserMessage} from './types'
import {useCallback, useReducer} from 'react'
import type {SubmitState} from './ChatContext'
import {InternalError} from '@zel-labs/shared/firebase'
import {typeConverter} from '@zel-labs/shared/firebase'
import type {MessageTree} from './MessageThread'
import { trackEvent, useFirebaseAnalytics, useFirebaseAuth } from '@zel-labs/shared/firebase'


export function usePromptSubmmitter(
  chat: DocumentSnapshot<Chat> | null | undefined,
  startNewChat: () => Promise<DocumentReference<Chat>>,
  selectChat: (chat: DocumentReference<Chat>) => void,
  thread: MessageTree[] | undefined,
  mode: 'chat' | 'view') {
  const {user} = useFirebaseAuth()
  const [reduceState, dispatch] = useReducer(promptSubmitReducer, {type: 'idle'})
  const { analytics } = useFirebaseAnalytics()

  const observeAgentMessage = useCallback(
    (snapshot: DocumentSnapshot<ChatMessage>) => {
      const status = snapshot.get('status')
      switch (status) {
        case 'generating':
          return dispatch({type: 'listen'})
        case 'completed':
          return dispatch({type: 'complete'})
        case 'fail':
          return dispatch({type: 'fail', error: new InternalError()})
      }
    },
    [dispatch]
  )

  const handleFirestoreError = useCallback(
    (error: FirestoreError) => {
      return dispatch({type: 'fail', error})
    }, [dispatch]
  )


  const submitPrompt = useCallback(
    async (prompt: string) => {
      if (chat === undefined) {
        throw new Error('Chat not initialized')
      }

      if (mode === 'view') {
        throw new Error('Cannot submit prompt in view mode')
      }

      const replyTo = thread?.[thread.length - 1]?.message.ref

      dispatch({type: 'post'})
      const {messageReference, chatReference} =
        await postPrompt(prompt, replyTo?.id, user?.uid ?? null, chat, startNewChat)

      if (chatReference != null) {
        selectChat(chatReference)
      }

      if( messageReference != null) {
        trackEvent(analytics, 'copilot_chat_submitted', {
          message: messageReference.path
        })
      }

      const unsubscribe = onSnapshot(
        messageReference, observeAgentMessage, handleFirestoreError
      )
      const timeout = window.setTimeout(
        () => {
          dispatch({type: 'timeout'})
        },
        10000
      )
      dispatch({type: 'wait', unsubscribe, timeout})

    },
    [chat, mode, thread, user?.uid, startNewChat, observeAgentMessage, handleFirestoreError, selectChat, analytics]
  )

  return {submitState: reduceState.type as SubmitState, submitPrompt}
}


async function postPrompt(
  prompt: string, parent: string | undefined, uid: string | null,
  chat: DocumentSnapshot<Chat> | null, startNewChat: () => Promise<DocumentReference<Chat>>
) {
  const isNewChat = chat == null

  const chatReference = isNewChat
    ? await startNewChat()
    : chat.ref

  const messagesCollection =
    collection(chatReference, 'messages')
      .withConverter(typeConverter<ChatMessage>())

  const created_at = serverTimestamp()
  const userMessage: WithFieldValue<UserMessage> = {
    content: prompt, type: 'human', parent: parent ?? null,
    created_at, uid
  }
  const userMessageRef =
    await addDoc(messagesCollection, userMessage)

  const requestRef: WithFieldValue<AgentMessage> = {
    content: '', type: 'agent', parent: userMessageRef.id,
    created_at, uid, status: 'pending'
  }

  const messageReference = await addDoc(messagesCollection, requestRef)
  return {messageReference, chatReference: isNewChat ? chatReference : undefined}
}

type ReduceAction =
  { type: 'post' } |
  { type: 'wait', unsubscribe: Unsubscribe, timeout: number } |
  { type: 'timeout' } |
  { type: 'listen' } |
  { type: 'fail', error: Error } |
  { type: 'complete' }

type ReduceState =
  { type: 'idle' } |
  { type: 'posting' } |
  { type: 'pending', unsubscribe: Unsubscribe, timeout: number } |
  { type: 'listening', unsubscribe: Unsubscribe } |
  { type: 'completed' } |
  { type: 'failed', error: Error }

function promptSubmitReducer(state: ReduceState, action: ReduceAction): ReduceState {
  if (action.type === 'post' && state.type === 'idle' || state.type === 'failed') {
    return {type: 'posting'}
  } else if (action.type === 'wait' && state.type === 'posting') {
    return {type: 'pending', unsubscribe: action.unsubscribe, timeout: action.timeout}
  } else if (action.type === 'timeout' && state.type === 'pending') {
    state.unsubscribe()
    return {type: 'failed', error: new Error('Timeout')}
  } else if (action.type === 'listen' && state.type === 'pending') {
    window.clearTimeout(state.timeout)
    return {...state, type: 'listening'}
  } else if (action.type === 'listen' && state.type === 'listening') {
    return state
  } else if (action.type === 'complete' && state.type === 'listening') {
    state.unsubscribe()
    return {type: 'idle'}
  } else if (action.type === 'fail' && state.type === 'listening') {
    state.unsubscribe()
    return {type: 'idle'}
  }

  throw new Error(`Unhandled action ${action.type} in state ${state.type}`)
}
