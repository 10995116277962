import { useEffect, useRef, useState } from 'react'

export interface RecorderControls {
  startRecording: () => void;
  stopRecording: () => void;
  togglePauseResume: () => void;
  recordingBlob?: Blob;
  isRecording: boolean;
  isPaused: boolean;
  recordingTime: number;
  mediaRecorder?: MediaRecorder;
}

export type MediaAudioTrackConstraints = Pick<
  MediaTrackConstraints,
  | 'deviceId'
  | 'groupId'
  | 'autoGainControl'
  | 'channelCount'
  | 'echoCancellation'
  | 'noiseSuppression'
  | 'sampleRate'
  | 'sampleSize'
>;


export type RecorderState = 'idle' | 'recording' | 'stopped' | 'paused' | 'failed'

// export function useAudioRecorder(
//   handleChunk: ((chunk: Blob) => void),
//   audioTrackConstraints?: MediaAudioTrackConstraints,
//   onNotAllowedOrFound?: (exception: DOMException) => void,
//   mediaRecorderOptions?: MediaRecorderOptions,
// ) {
//
//   const [recorder, setRecorder] = useState<MediaRecorder>()
//
//   useEffect(() => {
//     let isMounted = true
//     initAudioRecorder(audioTrackConstraints, mediaRecorderOptions).then(
//       (recorder) => {
//         if (isMounted) {
//           setRecorder(recorder)
//         }
//       }
//     ).catch(
//       (e) => {
//         if (isMounted) {
//           onNotAllowedOrFound?.(e)
//         }
//       }
//     )
//
//     return () => {
//       isMounted = false
//     }
//   }, [audioTrackConstraints, mediaRecorderOptions, onNotAllowedOrFound])
//
//   const {state, duration, error} = useAudioRecorderState(handleChunk, recorder)
//
//   return {recorder, state, duration, error}
// }

export function useAudioRecorderState(
  handleChunk: ((chunk: Blob) => void),
  recorder: MediaRecorder | undefined,
  autoStart = true,
  chunkSize = 30000
) {

  const [state, setState] = useState<RecorderState>('idle')
  const [error, setError] = useState<DOMException>()
  const [zeroTimestamp, setZeroTimestamp] = useState<number>()
  const [duration, setDuration] = useState<number>(0)

  useEffect(() => {
    const handleData = (event: BlobEvent) => handleChunk(event.data)
    const handleStart = () => {
      setZeroTimestamp(performance.now())
      setDuration(0)
      setState('recording')
    }
    const handleStop = () => setState('stopped')
    const handlePause = () => setState('paused')
    const handleResume = () => {
      setZeroTimestamp(performance.now() - (duration ?? 0))
      setState('recording')
    }
    const handleError = (event: Event) => {
      setState('failed')
      if (event instanceof ErrorEvent) {
        setError(event.error)
      }
    }

    if (autoStart && recorder && state === 'idle') {
      recorder.start(chunkSize)
    }

    if (recorder != null) {
      recorder.addEventListener('dataavailable', handleData)
      recorder.addEventListener('start', handleStart)
      recorder.addEventListener('pause', handlePause)
      recorder.addEventListener('resume', handleResume)
      recorder.addEventListener('stop', handleStop)
      recorder.addEventListener('error', handleError)

      return () => {
        recorder.removeEventListener('dataavailable', handleData)
        recorder.removeEventListener('start', handleStart)
        recorder.removeEventListener('pause', handlePause)
        recorder.removeEventListener('resume', handleResume)
        recorder.removeEventListener('stop', handleStop)
        recorder.removeEventListener('error', handleError)
      }
    }

  }, [autoStart, chunkSize, duration, error, handleChunk, recorder, state])

  useAnimationFrame(() => {
    if (state === 'recording' && zeroTimestamp != null) {
      setDuration(performance.now() - zeroTimestamp)
    }
  })

  return { state, duration, error }
}

/**
 * Initialize a MediaRecorder with the given audioTrackConstraints and mediaRecorderOptions
 * If the microphone permission is denied, a DOMException is thrown
 * @param audioTrackConstraints
 * @param mediaRecorderOptions
 */
export async function initAudioRecorder(audioTrackConstraints?: MediaAudioTrackConstraints, mediaRecorderOptions?: MediaRecorderOptions) {
  const p = await navigator.permissions.query({ name: 'microphone' as PermissionName })
  if (p.state === 'denied') {
    throw new DOMException('Microphone permission denied', 'NotAllowedError')
  }
  const stream = await navigator.mediaDevices.getUserMedia({ audio: audioTrackConstraints ?? true })
  return new MediaRecorder(stream, mediaRecorderOptions)
}


function useAnimationFrame(callback: (deltaTime: DOMHighResTimeStamp) => unknown, threshold = 0) {
  const requestRef = useRef<number>()
  const previousTimeRef = useRef<DOMHighResTimeStamp>()

  useEffect(() => {
    const animate = (time: DOMHighResTimeStamp) => {
      if (previousTimeRef.current === undefined) {
        previousTimeRef.current = time
      }
      const deltaTime = time - previousTimeRef.current
      if (deltaTime > threshold) {
        callback(deltaTime)
        previousTimeRef.current = time
      }

      requestRef.current = requestAnimationFrame(animate)
    }

    requestRef.current = requestAnimationFrame(animate)
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
        requestRef.current = undefined
      }
    }
  }, [callback, threshold])
}
