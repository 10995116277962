import type { DocumentReference, DocumentSnapshot } from 'firebase/firestore'
import { addDoc, arrayUnion, collection, serverTimestamp, Timestamp, updateDoc } from 'firebase/firestore'
import type { Coaching, Meeting } from '@zel-labs/shared/model'
import { typeConverter } from '@zel-labs/shared/firebase'

import { PageContainer } from '@zel-labs/shared/mui'
import { useNavigate, useParams } from 'react-router-dom'
import { CoachingContextProvider, useCoaching } from '../coaching'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Container, Fab, styled, Typography } from '@mui/material'
import { availableLanguages, LanguageSelector } from '../components/LanguageSelector'
import { Trans, useTranslation } from 'react-i18next'
import MicIcon from '@mui/icons-material/Mic'
import { initAudioRecorder, useAudioRecorderState } from '../components'

import NotStartedIcon from '@mui/icons-material/NotStarted'
import PauseIcon from '@mui/icons-material/Pause'
import StopIcon from '@mui/icons-material/Stop'
import type { UploadResult } from 'firebase/storage'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import { Routing } from '@zel-labs/routing'
import { trackEvent, useFirebaseAnalytics } from '@zel-labs/shared/firebase'

export function RecordMeetingPage() {
  const { coachingId } = useParams<{ coachingId: string }>()
  const { coachingSnapshot, role, coachingTitle } = useCoaching(coachingId)

  if (role !== 'coach' || coachingSnapshot == null) {
    return null
  }

  return <PageContainer title={coachingTitle ?? 'MAX'}>
    <CoachingContextProvider coaching={coachingSnapshot} role={role}>
      <MeetingRecorder coachingSnapshot={coachingSnapshot} />
    </CoachingContextProvider>
  </PageContainer>
}


function MeetingRecorder({ coachingSnapshot }: { coachingSnapshot: DocumentSnapshot<Coaching> }) {
  const { analytics } = useFirebaseAnalytics()
  const { i18n } = useTranslation()
  const defaultLanguage = useMemo(() => {
    const i18nLanguage = i18n.language?.replace('_', '-')
    if (availableLanguages.includes(i18n.language)) {
      return i18nLanguage
    } else if (!i18nLanguage.includes('-')) {
      const matchibgLanguage = availableLanguages.find(
        (lang) => lang.startsWith(i18nLanguage)
      )
      return matchibgLanguage ?? availableLanguages[0]
    } else {
      return availableLanguages[0]
    }
  }, [i18n.language])

  const [languageCode, setLanguageCode] = useState<string>(defaultLanguage)
  const [meetingRef, setMeetingRef] = useState<DocumentReference<Meeting> | undefined>(undefined)
  const [createState, setCreateState] = useState<'idle' | 'created'>('idle')
  const [recorder, setRecorder] = useState<MediaRecorder>()
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel>()
  const [errorKey, setErrorKey] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (coachingSnapshot != null) {
      trackEvent(analytics, 'meeting_recorder_opened', {
        coaching: coachingSnapshot.ref.path
      })
    }
  }, [analytics, coachingSnapshot])


  const startRecording = useCallback(async () => {
    if (coachingSnapshot == null) {
      return
    }
    try {
      const recorder = await initAudioRecorder(
        {
          sampleRate: 16000,
          autoGainControl: true,
          channelCount: 1
        }
      )
      setErrorKey(undefined)
      const meetingRef = await createMeetingDoc(coachingSnapshot.ref, languageCode)
      setMeetingRef(meetingRef)
      setRecorder(recorder)
      setWakeLock(await getWakeLock())
      setCreateState('created')
      trackEvent(analytics, 'meeting_recorder_started', {
        coaching: coachingSnapshot.ref.path,
        meeting: meetingRef.path
      })
    } catch (e) {
      if (e instanceof DOMException && e.name === 'NotAllowedError') {
        setErrorKey('meeting.create-meeting.permission_denied')
      } else {
        console.error(e)
        setErrorKey('meeting.create-meeting.unknown_error')
      }
    }

  }, [analytics, coachingSnapshot, languageCode])

  const chunks = useRef<Promise<UploadResult>[]>([])

  const uploadChunk = useCallback(
    async (data: Blob) => {
      if (recorder == null) {
        throw new Error('No recorder')
      }
      if (meetingRef == null) {
        throw new Error('No meeting')
      }

      const chunkIndex = chunks.current.length
      const chunkPath = `coachings/${coachingSnapshot.id}/meetings/${meetingRef.id}/chunks/${recorder.stream.id}-${chunkIndex.toString().padStart(4, '0')}.wav`
      const storage = getStorage()
      const storageRef = ref(storage, chunkPath)
      const chunkResult = await uploadBytes(storageRef, data, { contentType: 'audio/webm' })
      await updateDoc(meetingRef, {
        chunks: arrayUnion(chunkResult.ref.fullPath)
      })
      return chunkResult
    }, [coachingSnapshot, meetingRef, recorder]
  )

  const handleChunk = useCallback(
    async (data: Blob) => {
      const resultPromise = uploadChunk(data)
      chunks.current.push(resultPromise)
    }, [uploadChunk]
  )


  const { state, duration, error } =
    useAudioRecorderState(handleChunk, recorder, true, 30000)

  const navigate = useNavigate()
  useEffect(() => {
    const blockNav = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }
    if (['recording', 'paused'].includes(state)) {
      window.addEventListener('beforeunload', blockNav)
      return () => {
        window.removeEventListener('beforeunload', blockNav)
      }
    }
  }, [state])

  useEffect(() => {
    if (meetingRef != null) {
      if (state === 'stopped') {
        wakeLock?.release()
        setWakeLock(undefined)
        recorder?.stream.getTracks().forEach((track) => track.stop())

        Promise.all(chunks.current).then(
          () => {
            updateDoc(meetingRef, {
              status: 'recorded'
            })
            trackEvent(analytics, 'meeting_recorder_stopped', {
              meeting: meetingRef.path,
              coaching: meetingRef.parent.parent?.path
            })
            navigate(Routing.meeting(meetingRef))
          }
        )
      } else if (state === 'failed') {
        wakeLock?.release()
        setWakeLock(undefined)
        recorder?.stream.getTracks().forEach((track) => track.stop())
        Promise.all(chunks.current).then(
          () => {
            updateDoc(meetingRef, {
              status: 'recording_failed',
              error: error?.message
            })
            trackEvent(analytics, 'meeting_recorder_failed', {
              meeting: meetingRef.path,
              coaching: meetingRef.parent.parent?.path
            })
            navigate(Routing.meeting(meetingRef))
          }
        )
      }
    }
  }, [analytics, chunks, error, meetingRef, navigate, recorder, state, wakeLock])


  if (recorder == null) {
    return <CenteredContainer maxWidth="sm">
      {errorKey != null &&
        <Typography align="center" color="error"><Trans i18nKey={errorKey} /></Typography>
      }
      <Fab disabled={createState !== 'idle'} size="large" onClick={startRecording} color="error"><MicIcon
        fontSize="large" /></Fab>
      <Box maxWidth="xs">
        <LanguageSelector languageCode={languageCode} setLanguageCode={setLanguageCode} />
      </Box>
    </CenteredContainer>
  } else if (state === 'recording') {
    return <CenteredContainer maxWidth="sm">
      <Fab size="large" onClick={() => recorder.stop()} color="error"><StopIcon fontSize="large" /></Fab>
      <Box maxWidth="xs"> <RecordingDuration duration={duration} /></Box>
      <Fab size="small" onClick={() => recorder.pause()} color="primary"><PauseIcon /></Fab>
    </CenteredContainer>
  } else if (state === 'paused') {
    return <CenteredContainer maxWidth="sm">
      <Fab size="large" onClick={() => recorder.stop()} color="error"><StopIcon fontSize="large" /></Fab>
      <Box maxWidth="xs"> <RecordingDuration duration={duration} /></Box>
      <Fab size="small" onClick={() => recorder.resume()} color="primary"><NotStartedIcon fontSize="small" /></Fab>
    </CenteredContainer>
  } else if (state === 'stopped') {
    return <CenteredContainer maxWidth="sm">
      <Fab size="large" disabled={true} onClick={() => recorder.stop()} color="error"><StopIcon fontSize="large" /></Fab>
      <Box maxWidth="xs"> <RecordingDuration duration={duration} /></Box>
      <Fab size="small" disabled={true} onClick={() => recorder.resume()} color="primary"><NotStartedIcon
        fontSize="small" /></Fab>
    </CenteredContainer>
  } else if (state === 'failed') {
    return <CenteredContainer maxWidth="sm">
      <Fab size="large" disabled={true} onClick={() => recorder.stop()} color="error"><StopIcon fontSize="large" /></Fab>
      <Box maxWidth="xs"> <RecordingDuration duration={duration} /></Box>
      <Fab size="small" disabled={true} onClick={() => recorder.resume()} color="primary"><NotStartedIcon
        fontSize="small" /></Fab>
    </CenteredContainer>
  }
}

function RecordingDuration({ duration }: { duration: number }) {
  const formatted = useMemo(() => {
    const durationSeconds = Math.floor((duration ?? 0) / 1000)
    const hours = Math.floor(durationSeconds / (60 * 60))
    const minutesRemaining = durationSeconds % (60 * 60)

    const minutes = Math.floor(minutesRemaining / 60)
    const seconds = durationSeconds % 60

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }, [duration])

  return <Typography variant="h4" pr={1} pl={1}>{formatted}</Typography>
}

const CenteredContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  gap: theme.spacing(2)
}))

async function getWakeLock(): Promise<WakeLockSentinel> {
  return navigator.wakeLock.request('screen')
}

async function createMeetingDoc(coaching: DocumentReference<Coaching> | undefined, languageCode: string) {
  if (coaching == null) {
    throw new Error('No coaching')
  }
  const meetings = collection(coaching, 'meetings').withConverter(
    typeConverter<Meeting>()
  )
  return addDoc(meetings, {
    appointmentTime: Timestamp.now(),
    languageCode,
    uploadedAt: serverTimestamp(),
    recordingFileName: null,
    recording: null,
    transcript: null,
    status: 'recording',
    progress: null,
    lead: null,
    meetingNotes: null,
    title: null,
    audio: null
  })
}
