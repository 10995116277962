import {Link, Skeleton, Stack, Typography, styled,} from '@mui/material'
import React, {useMemo} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import type {Coaching} from '@zel-labs/shared/model'
import {useCoachingContext} from './CoachingContext'
import {Routing} from '@zel-labs/routing'
import {MeetingActions} from '../components'
import {MeetingContextProvider} from '../meeting'
import {useMeetingContext} from '../meeting'
import {ContentHeader} from '@zel-labs/shared/mui'
import {Trans} from 'react-i18next'
import {NewMeetingSpeedDial} from '../components/NewMeetingSpeedDial'

export function CoachingDetails() {
  const {coaching, meetings, coachingTitle, coachingSubtitle} = useCoachingContext()

  const {coach, coachee} = useMemo(
    () => coaching?.data() ?? ({} as Partial<Coaching>),
    [coaching]
  )


  if (coach == null || coachee == null || meetings == null) {
    return null
  }

  return <>
    <Stack direction="column" marginBottom={4}>
      <ContentHeader title={coachingTitle} subtitle={coachingSubtitle}/>
      {meetings.map((snapshot) =>
        <MeetingContextProvider key={snapshot.id} meeting={snapshot}>
          <MeetingInfo key={snapshot.id}/>
        </MeetingContextProvider>
      )}
    </Stack>
    <NewMeetingSpeedDial />
  </>
}

function MeetingInfo() {
  const {meeting, data, meetingDate} = useMeetingContext()

  if (meeting == null) {
    return null
  }

  return <MeetingBlock direction="column">
    <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={2}>
      <Typography variant="h6" component="h3" lineHeight={1.2} whiteSpace="nowrap" flexGrow={1} overflow="hidden"
                  textOverflow="ellipsis">
        <Link sx={{textDecoration: 'none', color: 'inherit'}} component={RouterLink} to={Routing.meeting(meeting?.ref)}>
          {data?.title ?? data?.recordingFileName ?? <Skeleton/>}
        </Link>
      </Typography>
      <Typography variant="caption" whiteSpace="nowrap"><Trans i18nKey="formats.short-date" values={{date: meetingDate}}/></Typography>
    </Stack>

    <Typography variant="body2" component="p">{data?.lead ?? <Skeleton/>}</Typography>

    <MeetingActions/>
  </MeetingBlock>
}

const MeetingBlock = styled(Stack, {name: 'InterviewBlock', slot: 'Root'})(
  ({theme}) => ({
    backgroundColor: theme.palette.background.default,
    gap: theme.spacing(1),
    marginBottom: theme.spacing(4),
  })
)
