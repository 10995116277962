import { DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, Link, Stack, TextField } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import type { FormEvent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useAuthenticationContext } from '..'
import type { UserCredential } from 'firebase/auth'
import { AuthErrorCodes, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile } from 'firebase/auth'
import { useFirebaseAnalytics, useFirebaseAuth } from '@zel-labs/shared/firebase'
import { FirebaseError } from 'firebase/app'
import { errorMessage } from './util'
import GoogleIcon from '@mui/icons-material/Google'
import { NoWrapButton, NoWrapFormHelperText } from './AuthDialog'
import * as Sentry from '@sentry/react'
import { Routing } from '@zel-labs/routing'
import { trackEvent } from '@zel-labs/shared/firebase'

export function SignUpForm() {
  const { t } = useTranslation()
  const { auth } = useFirebaseAuth()
  const { hideAuthentication, signIn } = useAuthenticationContext()
  const { analytics } = useFirebaseAnalytics()

  const [email, setEmail] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordCheck, setPasswordCheck] = useState('')

  const [emailError, setEmailError] = useState<string | null>(null)
  const [displayNameError, setDisplayNameError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [passwordCheckError, setPasswordCheckError] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)


  const [state, setState] = useState<'idle' | 'pending' | 'success' | 'failed'>('idle')

  const resetFields = useCallback(() => {
    setEmail('')
    setDisplayName('')
    setPassword('')
    setPasswordCheck('')
  }, [])

  const resetErrors = useCallback(() => {
    setEmailError(null)
    setDisplayNameError(null)
    setPasswordError(null)
    setPasswordCheckError(null)
    setError(null)
  }, [])


  const handleSuccess = useCallback(
    async (credential: UserCredential) => {
      trackEvent(analytics, 'signup_success')
      setState('success')
      resetErrors()
      resetFields()
      hideAuthentication()
      setState('idle')
    }, [hideAuthentication, resetErrors, resetFields, analytics]
  )


  const signInWithGoogle = useCallback(
    async () => {
      try {
        trackEvent(analytics, 'signup_google_started')
        setState('pending')
        const provider = new GoogleAuthProvider()
        const credential = await signInWithPopup(auth, provider)
        await handleSuccess(credential)
      } catch (e) {
        if (e instanceof FirebaseError) {
          if (e.code === 'auth/popup-blocked') {
            setError('Popup blocked, please allow popups')
          } else if (e.code === 'auth/popup-closed-by-user') {
            setError('Popup closed by user')
          } else {
            setError(errorMessage(e))
            Sentry.captureException(e, { data: { event: 'signUp', provider: 'google' } })
          }
          setState('failed')
        }
      }
    }, [auth, handleSuccess, analytics])

  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      try {
        trackEvent(analytics, 'signup_password_submitted')
        setState('pending')
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        await updateProfile(userCredential.user, { displayName })

        await handleSuccess(userCredential)
      } catch (e) {
        Sentry.captureException(e, { data: { event: 'signUp', provider: 'email' } })
        if (e instanceof FirebaseError) {
          if (e.code === AuthErrorCodes.INVALID_EMAIL) {
            setEmailError('Invalid email')
          } else if (e.code === AuthErrorCodes.EMAIL_EXISTS) {
            setEmailError(AuthErrorCodes.EMAIL_EXISTS)
          } else if (e.code === AuthErrorCodes.INVALID_LOGIN_CREDENTIALS) {
            setError('Invalid email or password')
          } else if (e.code === 'auth/missing-password') {
            setPasswordError('Missing password')
          } else {
            setError(errorMessage(e))
          }
        } else {
          setError(errorMessage(e))
        }
        setState('failed')
      }
    },
    [handleSuccess, auth, displayName, email, password, analytics]
  )

  useEffect(() => {
    trackEvent(analytics, 'signup_opened')
  }, [analytics])

  return <form onSubmit={submit}>
    <DialogTitle><Trans i18nKey="auth:signUp.form.title" /></DialogTitle>
    <DialogContent>
      <Stack gap={1} flexGrow={1}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
          <FormHelperText><Trans i18nKey="auth:signUp.form.signUpWithOther" /></FormHelperText>
          <Stack direction="row">
            <IconButton onClick={signInWithGoogle}><GoogleIcon /></IconButton>
          </Stack>
        </Stack>
        <FormHelperText><Trans i18nKey="auth:signUp.form.useCredentials" /></FormHelperText>

        <TextField
          size="small" fullWidth={true}
          label={t('auth:signUp.form.email')} value={email}
          error={emailError != null} helperText={emailError}
          onChange={e => setEmail(e.target.value)} />

        <TextField
          size="small" fullWidth={true}
          label={t('auth:signUp.form.displayName')} value={displayName}
          error={displayNameError != null} helperText={displayNameError}
          onChange={e => setDisplayName(e.target.value)} />

        <TextField
          size="small" fullWidth={true} type="password"
          error={passwordError != null} helperText={passwordError}
          label={t('auth:signUp.form.password')} value={password}
          onChange={e => setPassword(e.target.value)} />

        <TextField
          size="small" fullWidth={true} type="password"
          error={passwordCheckError != null} helperText={passwordCheckError}
          label={t('auth:signUp.form.passwordCheck')} value={passwordCheck}
          onChange={e => setPasswordCheck(e.target.value)} />

        {error != null &&
          <FormHelperText error={true}>{error}</FormHelperText>}
        <FormHelperText><Trans i18nKey="auth:signUp.form.consent">
          <Link href={Routing.termsOfUse()} target="_blank" rel="noopener noreferrer" />
          <Link href={Routing.privacyStatement()} target="_blank" rel="noopener noreferrer" />
        </Trans></FormHelperText>
      </Stack>
    </DialogContent>
    <DialogActions>
      <Stack direction="row" justifyContent="flex-start" alignItems="baseline" flexWrap="wrap">
        <NoWrapFormHelperText><Trans i18nKey="auth:signUp.form.registered" /></NoWrapFormHelperText>
        <NoWrapButton variant="text" sx={{ textTransform: 'inherit' }} onClick={signIn} size="small"><Trans
          i18nKey="auth:signUp.form.signIn" /></NoWrapButton>
      </Stack>
      <LoadingButton sx={{ flexShrink: 0 }} type="submit" variant="contained" color="primary"
                     loading={state === 'pending'} autoFocus={true}>
        <Trans i18nKey="auth:signUp.form.submit" />
      </LoadingButton>
    </DialogActions>
  </form>
}

