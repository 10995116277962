import {PageContainer} from '@zel-labs/shared/mui'

import React from 'react'
import {Stack} from '@mui/material'
import {Coachings, useCoachings} from './Coachings'
import {useTranslation} from 'react-i18next'

/* eslint-disable jsx-a11y/aria-role */

export function AdminPage() {
  const all = useCoachings('admin')
  const {t} = useTranslation()
  return <PageContainer title={t('title')}>
    <Stack direction="column">
      <Coachings role="admin" title={t('dashboard.coachings.coachings-header')} coachings={all}/>
    </Stack>
  </PageContainer>

}
