import {useParams} from 'react-router-dom'

import {Skeleton, Stack, Typography} from '@mui/material'
import {CoachingContextProvider} from '../coaching'
import {MeetingContextProvider, useMeetingContext} from '../meeting'
import {Breadcrumbs, HomeBreadcrumb, PageContainer} from '@zel-labs/shared/mui'
import {DiscBreadcrumb} from './DiscBreadcrumb'
import {Trans, useTranslation} from 'react-i18next'
import * as React from 'react'
import {useDiscAssessments} from './useDiscAssessments'
import { useEffect, useMemo } from 'react'
import {SummaryMarkdown} from '../components'
import {CoachingBreadcrumb, useCoaching} from '../coaching'
import {MeetingBreadcrumb, useMeeting} from '../meeting'
import { trackEvent } from '@zel-labs/shared/firebase'
import { useFirebaseAnalytics } from '@zel-labs/shared/firebase'

export function DiscPage() {
  const {coachingId, meetingId} = useParams<{ coachingId: string, meetingId: string }>()
  const {coachingSnapshot, role} = useCoaching(coachingId)
  const {meetingSnapshot} = useMeeting(meetingId, coachingSnapshot)

  if (role == null) {
    return null
  }

  return <CoachingContextProvider coaching={coachingSnapshot} role={role}>
    <MeetingContextProvider meeting={meetingSnapshot}>
      <DiscLayout/>
    </MeetingContextProvider>
  </CoachingContextProvider>
}

function DiscLayout() {
  const {data} = useMeetingContext()
  const {t} = useTranslation()

  return <PageContainer title={data?.title ?? data?.recordingFileName ?? t('meeting.assessments.lq.title')}>
    <Breadcrumbs separator="›">
      <HomeBreadcrumb/>
      <CoachingBreadcrumb/>
      <MeetingBreadcrumb/>
      <DiscBreadcrumb/>
    </Breadcrumbs>
    <MeetingDisc/>
  </PageContainer>
}


function MeetingDisc() {
  const {assessments} = useDiscAssessments()

  const assessmentSnapshot = useMemo(
    () => assessments?.[0],
    [assessments]
  )

  const data = useMemo(
    () => assessmentSnapshot?.data(),
    [assessmentSnapshot]
  )

  const {meetingTitle, meeting} = useMeetingContext()
  const {t} = useTranslation()
  const { analytics } = useFirebaseAnalytics()

  const meetingPath = useMemo(() => meeting?.ref.path, [meeting])

  useEffect(() => {
    if (meetingPath != null) {
      trackEvent(analytics, 'meeting_disc_opened', {
        meeting: meetingPath
      })
    }
  }, [analytics, meetingPath])

  if (assessments == null) {
    return null
  }

  if (assessmentSnapshot == null) {
    return <Stack direction="row" alignItems="center" gap={2} my={2}>
      <Typography variant="body1">No DISC assessment</Typography>
    </Stack>
  }

  return <Stack direction="column">
    <Typography variant="h3" component="h1">{t('meeting.assessments.disc.title')}</Typography>
    <Typography variant="subtitle1">{meetingTitle ?? <Skeleton/>}</Typography>
    <Typography variant="h4" component="h2" pt={2} mb={2}><Trans
      i18nKey="meeting.assessments.disc.assessment"/></Typography>
    {data?.assessment != null
      ? <SummaryMarkdown>{data?.assessment}</SummaryMarkdown>
      : <Skeleton variant="rectangular" height="4rwm" width="100%"/>
    }

    <Typography variant="h4" component="h2" pt={4} mb={2}><Trans
      i18nKey="meeting.assessments.disc.suggestions"/></Typography>
    {data?.suggestions != null
      ? <SummaryMarkdown>{data?.suggestions}</SummaryMarkdown>
      : <Skeleton variant="rectangular" height="4rem" width="100%"/>
    }
  </Stack>
}

