import * as React from 'react'
import { getFirestore } from 'firebase/firestore'
import { getPerformance } from 'firebase/performance'
import { useFirebaseApp } from './FirebaseAppContext'

export function useFirestore(databaseId?: string) {
  const app = useFirebaseApp()
  return React.useMemo(
    () => (databaseId ? getFirestore(app, databaseId) : getFirestore(app)),
    [app, databaseId]
  )
}

export function usePerformance() {
  const app = useFirebaseApp()
  return React.useMemo(() => getPerformance(app), [app])
}
